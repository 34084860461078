body{
  font-size:14px;
  max-width: 100vw;
  margin: 0;
  background-color: #000;
  
}


html{
  padding: env(safe-area-inset); 
 height: 100vh;
}

.form-control:focus{
  border: none;
  box-shadow:none;
}
.span{
  box-shadow:none;
}
.form-control {
  border: 0;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  margin-left: 0;
}

.ant-form-item-with-help .ant-form-item-explain{
  width:300px;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}
.ant-tabs >.ant-tabs-nav{
 
}

.ant-tabs .ant-tabs-content-holder {


}


button.xmint-btn {
  font-family:"proxima_nova_rgregular";
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  width: 300px;
   border: 1px solid;
  background: transparent;
  color: white;
  border-color: #26F8FF;
  height: 40px;
  border-radius: 2px;

}

.crossmintParagraph-d1-0-2-5 {
  color: "";
}



button.xmint-btn:hover {
  font-family:"proxima_nova_rgregular";
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  width: 300px;
   border: 1px solid;
  background: #26F8FF;
  color: #0a0519;
  border-color: #26F8FF;
  height: 40px;
  border-radius: 2px;

}




.ant-card {
  background-color: #343a40;
  border:1px solid #343a40;
  color:'white';
}
/*
.ant-card:hover {
  background-color: #0A0519;
  border:1px solid #1AADB2;
  transform: scale(1.05);
  opacity: 1.9;
  color:'white';
}
*/

.ant-card .ant-card-body {
  padding: 0;
}

.ant-card .ant-card-meta-description{
  color: #fff; 
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -5%;
 
}

.ant-card .ant-card-meta-title {
 color: #fff; 
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

video {
  object-fit: cover;
  overflow-clip-margin: content-box;
  overflow: clip;

}


/* width */
::-webkit-scrollbar {
  width: 8px;
  max-height:5px;
 
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 12px grey; 
  border-radius: 2px;
  height: 100%;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 8px;
  border: 1px solid #26F8FF;


}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #26F8FF; 
  border-radius: 8px;
  border: 1px solid #26F8FF;
}


@media only screen and (max-width: 600px) {

  .leaflet-bottom{
    bottom: 10%;
  }
  .loadingSpin{
    color:"#fff";
    font-size: 24px;
  }
.btnText{
font-size:8px;
}
  }

  @media only screen and (min-width: 768px) {
  
    .leaflet-bottom{
      bottom: 2%;
    }
    .loadingSpin{
      color:"#0A0519";
      font-size: 24px;
    }
.btnText{
font-size:14px;
}

  
  }



.InteractiveVideo_interactive-video__Ej1qP {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  height: -webkit-fill-available;
  justify-content: center;
  position: relative;
  width: 100%;

}
.InteractiveVideo_interactive-video__Ej1qP #editTextButton,
.InteractiveVideo_interactive-video__Ej1qP #hiddenInput {
  display: none;
}
.InteractiveVideo_interactive-video__Ej1qP video::-webkit-media-controls {
  pointer-events: none;
}
.css-fe8aaf {
  z-index: 3;
  align-self: baseline;
  position: absolute;
  inset: auto 10px 10px auto;
  min-width: 0px !important;
}


.app_app__9OAH2 {
  background: #000;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 100%;
  height: -webkit-fill-available;
  justify-content: center;
  position: relative;
  position: fixed;
  width: 100%;
  z-index: -1;
}

video::-webkit-media-controls-enclosure {
  display:none !important;
}

.discoverTopRow{
  position: fixed;
  background-color: #282828;
  top:0;
  width: 100vw;
  z-index: 100;
 
}
.activeComp{
  z-index: 10;
margin-top: 50px;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .leaflet-container {
    height: 85vh;
    }

  .activeButtonDiscover{
    width:100px;
    background-color:#ffffff47;
    height:50px;
    border-radius:8px;
    border:none;
    color:#ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .ButtonDiscover{
   
    width:100px;
    background-color:transparent;
    height:50px;
    border:none;
    color:#ffffff;
    font-size: 14px;
  }
  .disoverRow{
    display: flex;
    flex-direction: column;
    align-items:center;
   

  }
  .discoverCard{
    width:320px;
    border-radius: 20px;
    background:#282828;
    padding: 6.5%;
  }
  .discoverCardImage{
    width:276px;
    height:276px;
    border-radius: 20px;
  }
  .discoverHead{
    display: flex;
    flex-direction: column;
   align-items:center;
    color:#ffffff;
   
    margin-top:2%;
    font-size:28px;
    font-weight:bold;
  }
  .cardDescRow{
    height:60px;
    margin-top: 4%;
  }
  .cardName{
    color:#ffffff;
    font-size:16px;
    font-weight:bold;
  }
  .cardDesc{
    color:#ffffff;
    font-size:11px;
    width:200px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
  }
  .cardDescRowVisit{
    margin-top: 4%;
  }
  .visitBtn{
    background:#161616;
    width:100px;
    font-size:14px;
    border:none;
    border-radius:50px;
    color:#ffffff;
  }


  }

 


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .leaflet-container {
    height: 85vh;
    }

  .activeButtonDiscover{
    width:100px;
    background-color:#ffffff47;
    height:50px;
    border-radius:8px;
    border:none;
    color:#ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .ButtonDiscover{
   
    width:100px;
    background-color:transparent;
    height:50px;
    border:none;
    color:#ffffff;
    font-size: 14px;
  }
  .disoverRow{
 margin-left: 25%;

  }
  .discoverCard{
    width:320px;
    border-radius: 20px;
    background:#282828;
    padding: 5.5%;
  }
  .discoverCardImage{
    width:276px;
    height:276px;
    border-radius: 20px;
  }
  .discoverHead{

    color:#ffffff;
    margin-left: 25%;

    margin-top:2%;
    font-size:28px;
    font-weight:bold;
  }
  .cardDescRow{
    height:60px;
    margin-top: 4%;
  }
  .cardName{
    color:#ffffff;
    font-size:16px;
    font-weight:bold;
  }
  .cardDesc{
    color:#ffffff;
    font-size:11px;
    width:200px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
  }
  .cardDescRowVisit{
    margin-top: 4%;
  }
  .visitBtn{
    background:#161616;
    width:100px;
    font-size:14px;
    border:none;
    border-radius:50px;
    color:#ffffff;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .leaflet-container {
    height: 96vh;
    }

  .activeButtonDiscover{
    width:100px;
    background-color:#ffffff47;
    height:50px;
    border-radius:8px;
    border:none;
    color:#ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .ButtonDiscover{
   
    width:100px;
    background-color:transparent;
    height:50px;
    border:none;
    color:#ffffff;
    font-size: 14px;
  }
  .disoverRow{
margin-left: 7%;
   

  }
  .discoverCard{
    width:320px;
    border-radius: 20px;
    background:#282828;
    padding: 5.5%;
  }
  .discoverCardImage{
    width:276px;
    height:276px;
    border-radius: 20px;
  }
  .discoverHead{
    margin-left: 7%;
   align-items:center;
    color:#ffffff;
   
    margin-top:2%;
    font-size:28px;
    font-weight:bold;
  }
  .cardDescRow{
    height:60px;
    margin-top: 4%;
  }
  .cardName{
    color:#ffffff;
    font-size:16px;
    font-weight:bold;
  }
  .cardDesc{
    color:#ffffff;
    font-size:11px;
    width:200px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
  }
  .cardDescRowVisit{
    margin-top: 4%;
  }
  .visitBtn{
    background:#161616;
    width:100px;
    font-size:14px;
    border:none;
    border-radius:50px;
    color:#ffffff;
  }


}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {


  .activeButtonDiscover{
    width:100px;
    background-color:#ffffff47;
    height:50px;
    border-radius:8px;
    border:none;
    color:#ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .ButtonDiscover{
   
    width:100px;
    background-color:transparent;
    height:50px;
    border:none;
    color:#ffffff;
    font-size: 14px;
  }
  .disoverRow{
margin-left: 7%;
   

  }
  .discoverCard{
    width:320px;
    border-radius: 20px;
    background:#282828;
    padding: 5.5%;
  }
  .discoverCardImage{
    width:276px;
    height:276px;
    border-radius: 20px;
  }
  .discoverHead{
    margin-left: 7%;
   align-items:center;
    color:#ffffff;
   
    margin-top:2%;
    font-size:28px;
    font-weight:bold;
  }
  .cardDescRow{
    height:60px;
    margin-top: 4%;
  }
  .cardName{
    color:#ffffff;
    font-size:16px;
    font-weight:bold;
  }
  .cardDesc{
    color:#ffffff;
    font-size:11px;
    width:200px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
  }
  .cardDescRowVisit{
    margin-top: 4%;
  }
  .visitBtn{
    background:#161616;
    width:100px;
    font-size:14px;
    border:none;
    border-radius:50px;
    color:#ffffff;
  }


 
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .activeButtonDiscover{
    width:180px;
    background-color:#ffffff47;
    height:50px;
    border-radius:8px;
    border:none;
    color:#ffffff;
    font-size: 18px;
    font-weight: bold;
  }
  .ButtonDiscover{
   
    width:180px;
    background-color:transparent;
    height:50px;
    border:none;
    color:#ffffff;
    font-size: 18px;
  }
  .disoverRow{
    margin-left: 4%;
    margin-right: 0%;
  }
  .discoverCard{
    width:320px;
    border-radius: 20px;
    background:#282828;
    padding: 5.5%;
  }
  .discoverCardImage{
    width:276px;
    height:276px;
    border-radius: 20px;
  }
  .discoverHead{
    color:#ffffff;
    margin-left:4%;
    margin-top:2%;
    font-size:28px;
    font-weight:bold;
  }
  .cardDescRow{
    height:60px;
    margin-top: 4%;
  }
  .cardName{
    color:#ffffff;
    font-size:16px;
    font-weight:bold;
  }
  .cardDesc{
    color:#ffffff;
    font-size:11px;
    width:200px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
  }
  .cardDescRowVisit{
    margin-top: 4%;
  }
  .visitBtn{
    background:#161616;
    width:100px;
    font-size:14px;
    border:none;
    border-radius:50px;
    color:#ffffff;
  }
  .leaflet-container {
  height: 93vh;
  }
  
}

